/**
 * 短信js
 */

import axios from "@/resources/js/request";

const request = axios.request;
/**
 * 发送手机验证码
 * @param phone
 */
export const sendPhoneCode = ({phone}) => {
  return request.doPost('/aiocssms/sendPhoneCode', {
    phone: phone,
  });
}
